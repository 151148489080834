import React, { useState } from 'react';

import { InputAdornment, IconButton } from '@material-ui/core';

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import TextField from './TextField';

export default function PasswordField(props: any) {
  const [showPassword, toggleVisibility] = useState(false);
  return (
    <TextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={() => toggleVisibility(!showPassword)}
              tabIndex={-1}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
      {...props}
    />
  );
}
