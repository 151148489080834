import React from 'react';

import {
  TextField as FormikTextField,
  TextFieldProps,
} from 'formik-material-ui';

interface Props extends TextFieldProps {
  InputProps: object;
}

export default function TextField({ InputProps = {}, ...props }: Props) {
  return (
    <FormikTextField
      InputProps={{ disableUnderline: true, ...InputProps }}
      {...props}
    />
  );
}
