import React, { useState } from 'react';

import { Formik, Form, Field } from 'formik';
import validator from 'validator';

import {
  Button,
  Snackbar,
  SnackbarContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import { spacing } from '@material-ui/system';

import styled from 'styled-components';

import { TextField, PasswordField } from './Fields';

interface Props {
  onSubmit: (values: {
    email: string;
    password: string;
    givenName: string;
    familyName: string;
  }) => Promise<any>;
}

export default function SignUpForm({ onSubmit }: Props) {
  const [message, setSnackMessage] = useState('');

  return (
    <Formik
      initialValues={{ email: '', givenName: '', familyName: '', password: '' }}
      onSubmit={(
        { email, givenName, familyName, password },
        { setFieldError },
      ) => {
        return onSubmit({
          email,
          password,
          givenName,
          familyName,
        }).catch(err => {
          if (err && err.code === 'user_exists') {
            setSnackMessage('La création du compte à échoué');
            setFieldError('email', 'Cette adresse est déjà utilisée');
          }
        });
      }}
      validate={({ email, givenName, familyName, password }) => {
        const errors: {
          email?: string;
          givenName?: string;
          familyName?: string;
          password?: string;
        } = {};
        if (!email || validator.isEmpty(email)) {
          errors.email = "L'email est requis";
        } else if (!validator.isEmail(email)) {
          errors.email = "L'email est invalide";
        }
        if (!givenName || validator.isEmpty(givenName)) {
          errors.givenName = 'Merci de saisir votre prénom';
        }
        if (!familyName || validator.isEmpty(familyName)) {
          errors.familyName = 'Merci de saisir votre nom de famille';
        }
        if (!password || validator.isEmpty(password)) {
          errors.password = 'Vous devez créer un mot de passe';
        }
        return errors;
      }}
    >
      {({ setSubmitting }) => {
        function handleClose() {
          setSubmitting(false);
          setSnackMessage('');
        }

        return (
          <StyledForm>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              autoHideDuration={5000}
              onClose={handleClose}
              open={!!message}
            >
              <ErrorSnack
                action={[
                  <IconButton color="inherit" key="close" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>,
                ]}
                message={
                  <MessageContainer>
                    <WarningIcon />
                    <Message color="inherit" variant="body1">
                      {message}
                    </Message>
                  </MessageContainer>
                }
              />
            </Snackbar>
            <Field
              component={TextField}
              label="Email address"
              margin="normal"
              name="email"
              placeholder="Email address"
              type="email"
              variant="filled"
            />
            <Field
              component={PasswordField}
              label="Password"
              margin="normal"
              name="password"
              placeholder="Password"
              variant="filled"
            />
            <Field
              component={TextField}
              label="Prénom"
              margin="normal"
              name="givenName"
              placeholder="Prénom"
              type="text"
              variant="filled"
            />
            <Field
              component={TextField}
              label="Nom"
              margin="normal"
              name="familyName"
              placeholder="Nom"
              type="text"
              variant="filled"
            />
            <SignUpButton
              color="primary"
              mt={4}
              size="large"
              type="submit"
              variant="contained"
            >
              {'Créer votre compte'}
            </SignUpButton>
          </StyledForm>
        );
      }}
    </Formik>
  );
}

const StyledForm = styled<any>(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SignUpButton = styled(Button)`
  ${spacing}
`;

const ErrorSnack = styled(SnackbarContent)`
  background-color: ${({ theme }) => theme.palette.error.dark};
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Message = styled(Typography)`
  margin-left: 16px;
`;
