import React from 'react';

import { Paper, Divider, Typography, Button } from '@material-ui/core';

import styled from 'styled-components';
import { spacing, flexbox } from '@material-ui/system';

import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import { useAuthentication } from '../components/Authentication';

import { LoginForm } from '../components/Forms';
import { FixedImage } from '../components/Trainings/types';

interface Props {
  data: {
    file: FixedImage;
  };
}

export default function Login({ data }: Props) {
  const { login } = useAuthentication();

  return (
    <Container>
      <StyledPaper p={9}>
        <LogoContainer>
          <Logo fixed={data.file.childImageSharp.fixed} ml="38px" />
        </LogoContainer>
        <Section>
          <Typography gutterBottom variant="h6">
            {'Identifiez-vous'}
          </Typography>
          <Typography align="left" gutterBottom variant="body1">
            {'Connectez-vous avec votre compte crpm'}
          </Typography>
          <LoginForm onSubmit={login} />
        </Section>
        <StyledDivider m="auto" orientation="vertical" />
        <Section justifyContent="space-between">
          <div>
            <Typography gutterBottom variant="h5">
              {"Vous n'avez pas encore de compte ?"}
            </Typography>
            <Typography align="left" gutterBottom variant="body1">
              {'Inscrivez-vous maintenant pour profiter des avantages CRPM'}
            </Typography>
          </div>
          <StyledButton component={Link} to="/sign-up" variant="outlined">
            {'Créer un compte personnel'}
          </StyledButton>
        </Section>
      </StyledPaper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
`;

const StyledPaper = styled(({ ...props }) => <Paper {...props} />)`
  ${spacing}
  display: grid;
  grid-row-gap: 72px;
  grid-column-gap: 72px;
  grid-template-columns: 360px auto 360px;
`;

const StyledButton = styled(props => <Button {...props} />)`
  ${spacing}
`;

const LogoContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: span 3;
  place-self: center;
`;

const Logo = styled(Img)`
  ${spacing}
`;

const StyledDivider = styled(Divider)`
  ${spacing}
`;

const Section = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: column;
`;

export const query = graphql`
  query {
    file(name: { regex: "/logo/" }) {
      childImageSharp {
        fixed(width: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
