import React from 'react';

import { Formik, Form, Field } from 'formik';
import validator from 'validator';

import { Button } from '@material-ui/core';
import { spacing } from '@material-ui/system';

import styled from 'styled-components';

import { TextField, PasswordField } from './Fields';

const StyledForm = styled<any>(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LoginButton = styled(Button)`
  ${spacing}
`;

interface Props {
  onSubmit: (values?: { email: string; password: string }) => void;
}

export default function LoginForm({ onSubmit }: Props) {
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={({ email, password }) => {
        return onSubmit({
          email,
          password,
        });
      }}
      validate={({ email }) => {
        const errors: { email?: string } = {};
        if (!email || validator.isEmpty(email)) {
          errors.email = 'Email is required';
        } else if (!validator.isEmail(email)) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
    >
      {() => (
        <StyledForm>
          <Field
            component={TextField}
            label="Email address"
            margin="normal"
            name="email"
            placeholder="Email address"
            type="email"
            variant="filled"
          />
          <Field
            component={PasswordField}
            label="Password"
            margin="normal"
            name="password"
            placeholder="Password"
            variant="filled"
          />
          <LoginButton
            color="primary"
            mt={4}
            size="large"
            type="submit"
            variant="contained"
          >
            {'Connexion'}
          </LoginButton>
        </StyledForm>
      )}
    </Formik>
  );
}
